import React from 'react'
import PropTypes from 'prop-types'
import styles from './Message.module.scss'

export const Message = ({ content, createdAt, name, type, full }) => {
  let modifiers = ` ${styles[type]}`
  if (full) modifiers += ` ${styles._full}`

  return (
    <div className={`${styles.message} ${modifiers}`}>
      <div className={styles.content}>{content}</div>
      <div className={styles.infos}>
        <span>{name}</span>
        <span>, le {createdAt}</span>
      </div>
    </div>
  )
}

Message.propTypes = {
  content: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['user', 'supplier', 'admin']),
  full: PropTypes.bool,
}

Message.defaultProps = {
  type: 'user',
  full: false,
}
