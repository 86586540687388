import { useState } from 'react'
import { Button, Modal, TextArea } from 'ui'

const INITIAL_STATE = ''

export const FormContactModal = ({ shipping, onCancel, onSubmit }) => {
  const [message, setMessage] = useState(INITIAL_STATE)

  const cancel = () => {
    onCancel()
    setMessage(INITIAL_STATE)
  }

  const submit = () => {
    onSubmit(message)
    setMessage(INITIAL_STATE)
  }

  return (
    <Modal
      opened={!!shipping}
      title={`Contacter ${shipping ? shipping.supplierName : ''}`}
      closable
      leftActions={[<Button onClick={cancel}>Annuler</Button>]}
      actions={[
        <Button primary disabled={message === ''} onClick={submit}>
          Envoyer
        </Button>,
      ]}
      onClose={cancel}
    >
      {shipping && (
        <TextArea
          id={'Message'}
          label={`Votre message`}
          rows={6}
          autoResize
          focused
          required
          initialValue={message}
          onChange={v => setMessage(v)}
        />
      )}
    </Modal>
  )
}
