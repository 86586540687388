import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  ButtonLink,
  Card,
  Divider,
  Heading,
  Link,
  Logo,
  Rating,
  Stepper,
  Subheading,
  Message,
} from 'ui'
import styles from './CustomerShippingCard.module.scss'

export const CustomerShippingCard = props => {
  const { shipping: s, onContact, onReceived, onReview } = props
  const actions = []
  const profileLabels = ['Vous', s.supplierName, 'Ici Présent']

  if (s.shipperUrl && !s.receivedAt)
    actions.push(
      <ButtonLink external={true} to={s.shipperUrl}>
        Suivre mon colis
      </ButtonLink>
    )

  if (!s.cancelReason)
    actions.push(<Button onClick={onContact}>Contacter l'artisan</Button>)

  if (s.sentAt && !s.receivedAt && !s.cancelReason)
    actions.push(
      <Button onClick={onReceived} primary>
        J'ai reçu la commande
      </Button>
    )

  if (s.receivedAt && !s.review)
    actions.push(
      <Button onClick={onReview} primary>
        Je donne mon avis
      </Button>
    )

  return (
    <div>
      <Heading level={3}>{s.supplierName}</Heading>
      <Divider small />
      <Card padding={false} light actions={actions}>
        <div className={styles.resume}>
          <div className={styles.picture}>
            {s.image && (
              <img
                className={styles.pictureSource}
                src={s.image}
                alt={s.name}
              />
            )}
            {!s.image && (
              <div className={styles.pictureDefault}>
                <Logo />
              </div>
            )}
          </div>

          <div className={styles.container}>
            <div className={styles.header}>
              <Heading level={4}>{s.name}</Heading>
            </div>
            <Divider border />
            <div className={styles.progress}>
              <Stepper {...getSteps(s)} />
            </div>

            <Divider border />
            <div className={styles.infos}>
              <Subheading>Informations de livraison</Subheading>
              <ul className={styles.list}>
                <li className={styles.time}>
                  Expédié sous {s.shippingTime}{' '}
                  {s.shippingTime > 1 ? 'jours ouvrés' : 'jour ouvré'}
                </li>
                {s.shippingInfo && (
                  <li className={styles.more}>{s.shippingInfo}</li>
                )}
                {s.trackingRef && (
                  <li className={styles.more}>
                    Numéro de suivi :{' '}
                    <span>
                      {s.shipperUrl ? (
                        <Link to={s.shipperUrl} external={true}>
                          {s.trackingRef}
                        </Link>
                      ) : (
                        `${s.trackingRef}`
                      )}
                      {s.shipperName ? ` par ${s.shipperName}` : ''}
                    </span>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>

        {s.messages && (
          <div className={styles.messages}>
            <Subheading>Messagerie</Subheading>

            <div className={styles.messagesList}>
              {s.messages.map((m, i) => (
                <Message
                  key={i}
                  content={m.content}
                  createdAt={m.createdAt}
                  name={profileLabels[m.profile]}
                  type={profileKeys[m.profile]}
                />
              ))}
            </div>
          </div>
        )}

        {s.review && (
          <div className={styles.review}>
            <div className={styles.reviewIntro}>
              <Subheading>Votre avis</Subheading>

              <Rating value={s.review.rating} max={5} />
            </div>
            <Message
              content={s.review.comment}
              createdAt={s.review.createdAt}
              name={profileLabels[0]}
            />
            {s.review.answer && (
              <Message
                content={s.review.answer}
                createdAt={s.review.answeredAt}
                name={profileLabels[1]}
                type={'supplier'}
              />
            )}
          </div>
        )}
      </Card>
    </div>
  )
}

const profileKeys = ['user', 'supplier', 'admin']

const getSteps = s => {
  const steps = []
  steps.push({ title: 'Commandé', infos: getInfos(s.createdAt) })
  steps.push({
    title: 'Accepté',
    infos: getInfos(s.acceptedAt, s.cancelReason),
  })
  steps.push({ title: 'Expédié', infos: getInfos(s.sentAt, s.cancelReason) })
  steps.push({ title: 'Reçu', infos: getInfos(s.receivedAt, s.cancelReason) })

  let error = false
  let current = 1
  if (s.acceptedAt) current = 2
  if (s.sentAt) current = 3
  if (s.receivedAt) current = 4

  if (s.cancelReason) {
    error = true
    current++
  }

  return { current, error, steps }
}

const getInfos = (date, cancelReason = null) =>
  date ? `le ${date}` : cancelReason ? `Annulé : ${cancelReason}` : 'En attente'

CustomerShippingCard.propTypes = {
  onContact: PropTypes.func.isRequired,
  onReceived: PropTypes.func.isRequired,
  onReview: PropTypes.func.isRequired,
  shipping: PropTypes.shape({
    id: PropTypes.number.isRequired,
    acceptedAt: PropTypes.string,
    cancelReason: PropTypes.string,
    createdAt: PropTypes.string.isRequired,
    image: PropTypes.string,
    messages: PropTypes.arrayOf(
      PropTypes.shape({
        content: PropTypes.string,
        createdAt: PropTypes.string,
        profile: PropTypes.number,
      })
    ),
    name: PropTypes.string.isRequired,
    review: PropTypes.shape({
      answer: PropTypes.string,
      answeredAt: PropTypes.string,
      createdAt: PropTypes.string,
      comment: PropTypes.string,
      rating: PropTypes.number,
    }),
    receivedAt: PropTypes.string,
    sentAt: PropTypes.string,
    shipperName: PropTypes.string,
    shipperUrl: PropTypes.string,
    shippingInfo: PropTypes.string,
    shippingTime: PropTypes.number.isRequired,
    supplierName: PropTypes.string.isRequired,
    trackingRef: PropTypes.string,
  }),
}
