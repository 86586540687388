import { useCallback, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import {
  Annotation,
  Button,
  Divider,
  Heading,
  LayoutFixed,
  MenuPrimary,
  MenuSecondary,
  Subtitle,
} from 'ui'
import {
  contactBoxSupplier,
  getBoxStatus,
  receiveBoxSupplier,
  reviewBoxSupplier,
  updateBoxDetails,
} from 'services/customers'
import { waitForApi } from 'utils/api'
import { NotificationManager } from 'services/notifications'
import { goToAnchor } from 'utils/route'
import {
  ContactDetails,
  FormCoordinatesModal,
  FormContactModal,
  FormReviewModal,
  ShippingList,
  ShippingListResume,
} from '../../@commons'

import styles from './Status.module.scss'

const NOTIF_CHANGE_COORDINATES_SUCCESS = 'Vos coordonnées ont été mises à jour'
const NOTIF_MESSAGE_SUCCESS = 'Votre message a été envoyé'
const NOTIF_REVIEW_SUCCESS = 'Merci pour ce retour précieux !'

export const FeatureCustomersBoxStatus = () => {
  const { id, redeemingId, redeemingCode } = useParams()
  const location = useLocation()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [data, setData] = useState(null)
  const [links, setLinks] = useState([])
  const [changingDetails, setChangingDetails] = useState(null)
  const [contacting, setContacting] = useState(null)
  const [reviewing, setReviewing] = useState(null)

  const loadData = useCallback(async () => {
    return waitForApi(
      getBoxStatus({ id: parseInt(id), redeemingId, redeemingCode })
    )
  }, [id, redeemingId, redeemingCode])

  const onLoadDataSuccess = data => {
    setData(data.result)
    setLinks(getMenuLinks(data.result.shippings))
    setLoading(false)

    const hash = location.hash
    if (hash.length === 0) navigate(location.pathname + '#resume')
    else goToAnchor(hash.substring(1))
  }

  const onSubmitChangeDetails = async details => {
    setLoading(true)
    setChangingDetails(null)

    return waitForApi(updateBoxDetails({ id: parseInt(id), details }))
      .then(data => displayActionStatus(data, NOTIF_CHANGE_COORDINATES_SUCCESS))
      .then(loadData)
      .then(onLoadDataSuccess)
      .catch(onError)
  }

  const onSubmitContact = async message => {
    const { id: shippingId, threadRootKey } = contacting
    setLoading(true)
    setContacting(null)

    const data = {
      message,
      redeemingCode,
      redeemingId,
      shippingId,
      threadRootKey,
    }

    return waitForApi(contactBoxSupplier(data))
      .then(data => displayActionStatus(data, NOTIF_MESSAGE_SUCCESS))
      .then(loadData)
      .then(onLoadDataSuccess)
      .catch(onError)
  }

  const onSubmitReview = async (rating, comment) => {
    const { id: shippingId } = reviewing
    setLoading(true)
    setReviewing(null)

    const data = {
      shippingId,
      comment,
      rating,
      redeemingCode,
      redeemingId,
    }

    return waitForApi(reviewBoxSupplier(data))
      .then(data => displayActionStatus(data, NOTIF_REVIEW_SUCCESS))
      .then(loadData)
      .then(onLoadDataSuccess)
      .catch(onError)
  }

  const displayActionStatus = (data, message) => {
    if (data.exceptions?.length > 0) {
      data.exceptions.map(e => NotificationManager.addError(e))
    } else NotificationManager.addSuccess(message)
    return Promise.resolve(data)
  }

  const onReceived = s => {
    receiveBoxSupplier({
      redeemingCode,
      redeemingId,
      shippingId: s.id,
    })
    setReviewing(s)
  }

  const onError = error => {
    setLoading(false)
    setError(error[0])
  }

  const renderModals = () => {
    return (
      <>
        <FormContactModal
          shipping={contacting}
          onCancel={() => setContacting(null)}
          onSubmit={onSubmitContact}
        />
        <FormReviewModal
          shipping={reviewing}
          onCancel={() => setReviewing(null)}
          onSubmit={onSubmitReview}
        />
        <FormCoordinatesModal
          id={changingDetails}
          coords={data?.details}
          onCancel={() => setChangingDetails(null)}
          onSubmit={onSubmitChangeDetails}
        />
      </>
    )
  }

  return (
    <LayoutFixed
      title="Suivi de commande"
      nav={<MenuPrimary title={'Suivi de commande'} />}
      menu={<MenuSecondary links={links} />}
      loading={loading}
      error={error}
      loadData={loadData}
      onDataLoaded={onLoadDataSuccess}
      offscreen={renderModals()}
    >
      <div id={'resume'}>
        <Subtitle level={2}>Résumé</Subtitle>

        {data?.shippings?.length === 0 && (
          <div>
            <Divider />
            <Annotation>Aucune expédition pour le moment.</Annotation>
          </div>
        )}

        {data?.details && (
          <div>
            <Divider />
            <div className={styles.detailsHeading}>
              <Heading level={3}>Vos coordonnées</Heading>
              {data.canChangeDetails && (
                <div>
                  <Button small onClick={() => setChangingDetails(id)}>
                    Changer
                  </Button>
                </div>
              )}
            </div>
            <Divider small />
            <ContactDetails details={data.details} />
          </div>
        )}

        {data?.shippings?.length > 0 && (
          <div>
            <Divider />
            <Heading level={3}>Vos produits</Heading>
            <Divider />
            <ShippingListResume shippings={data.shippings} />
          </div>
        )}
      </div>

      {data?.shippings?.length > 0 && (
        <div>
          <Divider />
          <Divider />
          <Subtitle level={2}>Suivi d'expédition</Subtitle>
          <ShippingList
            shippings={data.shippings}
            onContact={setContacting}
            onReceived={onReceived}
            onReview={setReviewing}
          />
        </div>
      )}
    </LayoutFixed>
  )
}

const getMenuLinks = shippings => {
  const links = [
    { to: `#resume`, label: 'Résumé', primary: true },
    { divider: true },
  ]
  shippings.forEach(s => links.push({ to: `#${s.id}`, label: s.supplierName }))
  return links
}
