import React from 'react'
import PropTypes from 'prop-types'
import styles from './TextArea.module.scss'
import { IconClose } from 'ui'

export class TextArea extends React.Component {
  constructor(props) {
    super(props)
    this.textareaRef = React.createRef()
    this.wrapperRef = React.createRef()
    this.state = { validate: false }
  }

  componentDidMount() {
    this.setInputValue(this.props.initialValue)
    const { value, valid } = this.getInputState()

    if (this.props.focused) this.textareaRef.current.focus()
    if (this.props.onLoad) this.props.onLoad(value, valid)
    if (this.props.validate) this.setState({ validate: true })
    if (this.props.autoResize) {
      this.wrapperRef.current.dataset.replicatedValue =
        this.textareaRef.current.value
    }
  }

  componentDidUpdate(prevProps) {
    const { value } = this.getInputState()

    if (
      prevProps.initialValue !== this.props.initialValue &&
      this.props.initialValue !== value
    ) {
      this.setInputValue(this.props.initialValue)
      this.onChange()
    }

    if (prevProps.validate !== this.props.validate) {
      this.setState({ validate: this.props.validate })
    }
  }

  onInput = () => {
    if (this.props.autoResize) {
      this.wrapperRef.current.dataset.replicatedValue =
        this.textareaRef.current.value
    }
  }

  onBlur = () => {
    const { validate } = this.state
    const { value, valid } = this.getInputState()

    if (this.props.autoValidate && !validate) this.setState({ validate: true })
    if (this.props.onBlur) this.props.onBlur(value, valid)
  }

  onChange = () => {
    const { validate } = this.state
    const { value, valid } = this.getInputState()

    if (this.props.onChange) this.props.onChange(value, valid)
    if (this.props.autoValidate && !validate) this.setState({ validate: true })
    this.forceUpdate() // force update as the input state may have changed
  }

  setInputValue = value => {
    this.textareaRef.current.value = value
  }

  getInputState = () => {
    if (!this.textareaRef.current) return { value: null, valid: null }

    const value = this.textareaRef.current.value
    const valid = this.textareaRef.current.validity.valid

    return { value, valid }
  }

  reset = () => {
    this.setInputValue('')
    this.onChange()
  }

  render() {
    const { valid } = this.getInputState()
    const { disabled, label, light, id, required, resizable, rows, maxLength } =
      this.props

    let svgColor
    let modifiers = ''
    if (light) modifiers += ` ${styles._light}`
    if (resizable) modifiers += ` ${styles._resizable}`
    if (disabled) modifiers += ` ${styles._disabled}`

    if (this.state.validate) {
      svgColor = valid ? '#00cc67' : '#e40173'
      modifiers += valid ? ` ${styles._valid}` : ` ${styles._invalid}`
    }

    return (
      <div className={`${styles.control} ${modifiers}`}>
        <div ref={this.wrapperRef} className={styles.wrapper}>
          <textarea
            ref={this.textareaRef}
            name={id}
            className={styles.textarea}
            disabled={disabled}
            placeholder=" "
            rows={rows}
            required={required}
            onInput={this.onInput}
            onChange={this.onChange}
            onBlur={this.onBlur}
            maxLength={maxLength}
          ></textarea>
          <label htmlFor={id} className={styles.label}>
            {label}
          </label>
          {this.props.resetable && (
            <button
              className={styles.reset}
              onClick={this.reset}
              title={'Réinitialiser'}
            >
              <IconClose stroke={svgColor} />
            </button>
          )}
        </div>
      </div>
    )
  }
}

TextArea.propTypes = {
  autoValidate: PropTypes.bool,
  autoResize: PropTypes.bool,
  disabled: PropTypes.bool,
  focused: PropTypes.bool,
  light: PropTypes.bool,
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  initialValue: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onLoad: PropTypes.func,
  required: PropTypes.bool,
  resetable: PropTypes.bool,
  resizable: PropTypes.bool,
  rows: PropTypes.number,
  validate: PropTypes.bool,
  maxLength: PropTypes.number,
}

TextArea.defaultProps = {
  autoValidate: false,
  autoResize: false,
  disabled: false,
  initialValue: '',
  focused: false,
  light: false,
  onChange: null,
  onBlur: null,
  onLoad: null,
  required: false,
  resetable: false,
  resizable: false,
  rows: 1,
  validate: false,
  maxLength: null,
}
