import React from 'react'
import moment from 'moment'
import { formatDate } from 'dates'

/*
 * createdAt [String]
 * content:  [String]
 * profile:  [Int]
 */
export default class Message extends React.Component {
  from() {
    if (this.props.profile === 2) {
      return 'Ici Présent!'
    } else if (this.props.profile === 1) {
      return 'Nous'
    } else {
      return 'Client'
    }
  }

  date() {
    return formatDate(moment(this.props.createdAt))
  }

  content() {
    return this.props.content
      .split('\n')
      .map((c, i) => <p key={`${this.props.createdAt}-${i}`}>{c}</p>)
  }

  classNames() {
    if (this.props.profile === 2) return 'from-admin'
    else if (this.props.profile === 1) return 'from-us'
  }

  render() {
    return (
      <div className="message">
        <header className={this.classNames()}>
          De {this.from()}, le {this.date()}
        </header>
        <article>{this.content()}</article>
      </div>
    )
  }
}
