import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { formatDate } from 'dates'
import store from 'stores/SupplierMessagesPageStore'

/*
 * Properties:
 *
 * * id             Number
 * * supplierHash   String
 * * lastUpdatedAt  String
 * * customerName   String
 * * uniqueKey      String
 * * excerpt        String
 * * messageCount   Number
 * * closed         Boolean
 *
 */
export default class Thread extends React.Component {
  threadUrl() {
    return `/suppliers/${this.props.supplierHash}/messages/${this.props.uniqueKey}`
  }

  classNames() {
    if (this.props.closed) return 'thread closed'
    else return 'thread'
  }

  closeButton() {
    if (!this.props.closed) {
      return (
        <button
          className="btn"
          onClick={e => store.closeThread(this.props.uniqueKey)}
        >
          Marquer résolu
        </button>
      )
    }
  }

  closedLabel() {
    if (this.props.closed) return '[FERMÉ] '
  }

  render() {
    let date = moment(this.props.lastUpdatedAt)
    return (
      <div className={this.classNames()}>
        <header>
          {this.closeButton()}
          <Link to={this.threadUrl()}>
            <span>
              {this.closedLabel()}
              {this.props.customerName}
            </span>
            &nbsp;-&nbsp;
            <span>dernier message le {formatDate(date)}</span>
            &nbsp;-&nbsp;
            <span>
              {this.props.messageCount} message
              {this.props.messageCount > 1 ? 's' : ''}
            </span>
          </Link>
        </header>
        <article>{this.props.excerpt}</article>
      </div>
    )
  }
}
